import React from 'react';
import {BrowserRouter} from "react-router-dom";

import Header from "./Component/Layout/Header";
import Footer from "./Component/Layout/Footer";
import ScrollToTop from "./Component/Core/Widget/ScrollToTop/ScrollToTop";
import Router from "./Component/Core/Router";
import './App.scss';
import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  return (
      <BrowserRouter>
          <ScrollToTop />
          <Header />
          <Router />
          <Footer />
      </BrowserRouter>
  );
}

export default App;
