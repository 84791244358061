import React, { lazy, Suspense } from "react";
import {Route, Switch} from "react-router-dom";
import Loader from "./Widget/Loader";

const renderLoader = () => <Loader />;
/* Creation pages */
const CreationCollection = lazy(() => import('../Layout/Creation/CreationCollection'));
const CreationItem = lazy(() => import('../Layout/Creation/CreationItem'));
/* Member pages */
const MemberCollection = lazy(() => import('../Layout/Member/MemberCollection'));
const MemberItem = lazy(() => import('../Layout/Member/MemberItem'));
/* Service pages */
const ServiceCollection = lazy(() => import('../Layout/Service/ServiceCollection'));
const ServiceItem = lazy(() => import('../Layout/Service/ServiceItem'));
/* Other pages */
const Home = lazy(() => import('../Layout/Home/Home'));
const Page = lazy(() => import('../Layout/Page/Page'));
const News = lazy(() => import('../Layout/Post/News'));

export const Router = () => {
    return (
        <Suspense fallback={renderLoader()}>
            <Switch>
                <Route exact path="/" component={Home} />

                <Route exact path="/nos-creations/" component={CreationCollection} />
                <Route exact path="/nos-creations/:slug" render={(props) => <CreationItem {...props} key={Date.now()}/>} />

                <Route exact path="/nos-membres/" component={MemberCollection} />
                <Route exact path="/nos-membres/:slug" render={(props) => <MemberItem {...props} key={Date.now()}/>} />

                <Route exact path="/nos-prestations/" component={ServiceCollection} />
                <Route exact path="/nos-prestations/:slug" render={(props) => <ServiceItem {...props} key={Date.now()}/>} />

                <Route exact path="/actualites/" component={News} />
                <Route path="/:slug" component={Page} key={Date.now()}/>
            </Switch>
        </Suspense>
    );
}

export default Router;
