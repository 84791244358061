import React from 'react';
import * as $ from 'jquery';
import './Header.scss'
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import * as site from "../../core";

export const Header = () => {

    const path = window.location.protocol +"//"+ window.location.host;

    function buildDropdown() {
        $(function() {
            $("ul.navbar-nav > li.nav-item").hover(function () {
                let wdt = $(this).find("a.nav-link").width(),
                    dps = $(this).find(".dropdown-content")
                ;
                if (dps.width() < wdt) dps.width(wdt);
            });
        });
    }
    buildDropdown();

    return (
        <header>
            {/* Brand */}
            <div className="b-top">
                <div className="b-logo">
                    <a className="nav-link" href="/" title="Retourner à l'accueil">
                        <img src="/assets/files/logos/cdlp_white.png" alt="CDLP Logo" />
                    </a>
                </div>
                <div className="b-title">
                    <span className={"h1"}>{site.name}</span>
                    <div className="slogan">{site.slogan}</div>
                </div>
                <div className="b-support">
                    <a href={site.support} target={"_blank"} rel={"noopener noreferrer"} title={"Faire un don au CDLP"} className="btn btn-cdlp">Nous soutenir</a>
                </div>
            </div>

            {/* Menu navs */}
            <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
                <div className="text-left menus">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/nos-creations">Nos Créations</Link>
                                <div className="dropdown-content">
                                    <a href={path + "/nos-creations#theatre"}>Théâtre</a>
                                    <a href={path + "/nos-creations#cinema"}>Cinéma</a>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/nos-prestations">Nos Prestations</Link>
                                <div className="dropdown-content">
                                    <Link className="nav-link" to="/nos-prestations/theatre">Ateliers Théâtre</Link>
                                    <Link className="nav-link" to="/nos-prestations/lectures">Lectures</Link>
                                    <Link className="nav-link" to="/nos-prestations/evenements">Evénements</Link>
                                    <Link className="nav-link" to="/nos-prestations/anniversaires">Anniversaires</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/nos-membres">Nos Membres</Link>
                                <div className="dropdown-content">
                                    <HashLink className="nav-link" to="/nos-membres#actifs">Actifs</HashLink>
                                    <HashLink className="nav-link" to="/nos-membres#associes">Associés</HashLink>
                                    <HashLink className="nav-link" to="/nos-membres#supports">Supports</HashLink>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
