import React, {useEffect} from 'react';
import './ScrollToTop.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleUp} from "@fortawesome/free-solid-svg-icons";

export const ScrollToTop = () => {
    const classHide: string = 'hide';
    useEffect(() => {
        const item: HTMLElement | null = document.getElementById('scrollBtn');
        document.addEventListener("scroll", function(e) {
            if (item) {
                if (window.pageYOffset > 100) {
                    if (item.classList.contains(classHide)) {
                        item.classList.remove(classHide);
                    }
                } else if (!item.classList.contains(classHide)) {
                    item.classList.add(classHide);
                }
            }
        });
    });

    function scrollToTop(): void {
        window.scrollTo({
            top: 0, 
            behavior: "smooth"
        });
    }

    return (
        <>
        <div className="scroll-to-top">
            <button type={'button'} id={'scrollBtn'} className={classHide} onClick={() => scrollToTop()} title={'Retourner en haut de la page'}>
                <FontAwesomeIcon className={'nm-btn'} icon={faArrowAltCircleUp} />
                <span>Remonter</span>
            </button>
        </div>
        </>
    );
}

export default ScrollToTop;
