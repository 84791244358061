import { string } from "prop-types";
import conf from "./configuration.json";

const FILES_PATH = 'files/';

export const name = conf.app_name;
export const slogan = conf.app_slogan;
export const url = conf.app_url;
export const api_url = conf.api_url;
export const address = conf.company_address;
export const support = conf.company_support;

export function getFilePath(pathname: string, filename: string) {
    return api_url + FILES_PATH + pathname +'/'+filename;
}

export function getKeyByValue(object: any, value: string) {
    return Object.keys(object).find(key => object[key] === value);
}

export function smartSubstr(str: string, len: number, ellipsis: boolean = true) {
    let temp = str.substr(0, len);
    if (temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
        temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
    }
    return (ellipsis && str.length > len ? temp + "..." : temp);
}

export function getHash() {
    let hash = window.location.hash;
    let index = hash.indexOf("#");
    return (index !== -1 ? hash.substring(index + 1) : null);
}

export function shuffle(array: any) {
    let copy = [], n = array.length, i;
    // While there remain elements to shuffle…
    while (n) {
        // Pick a remaining element…
        i = Math.floor(Math.random() * array.length);
        // If not already shuffled, move it to the new array.
        if (i in array) {
            copy.push(array[i]);
            delete array[i];
            n--;
        }
    }
    return copy;
}

/* Add opacity effect on last long article displyed paragraph  */
const classReadMore = 'read-more-post';
export function readMoreHideParagraph(article: HTMLElement) {
    let items = article.querySelectorAll('p');
    let classHide = 'hide';
    let lastItem: any = null;

    items.forEach(function(item) {
        if (!item.classList.contains(classHide)) {
            lastItem = item;
        } else {
            lastItem.classList.add(classReadMore);
        }
    });
}

/* Hide/Show a long article paragraphs */
export function readMorePost(id: string) {
    const className = 'show';
    let newText = '';
    let article: HTMLElement = document.getElementById(id) as HTMLElement;
    let items = article.querySelectorAll('p');
    let button: HTMLButtonElement = document.getElementById('readMore') as HTMLButtonElement;

    items.forEach(function(item) {
        if (item.classList.contains(className)) {
            item.classList.remove(className);
            readMoreHideParagraph(article);
            newText = 'Voir plus';
        } else {
            item.classList.add(className);
            if (item.classList.contains(classReadMore)) {
                item.classList.remove(classReadMore);
            }
            newText = 'Voir moins';
        }
    });

    if (article.classList.contains('readMore')) {
        article.classList.remove('readMore');
    } else {
        article.classList.add('readMore');
    }

    if (button) {
        button.innerHTML = newText;
        article.scrollIntoView();    
    }
}

export function readMoreList(param: any) {
    const className = 'show';
    let newText = "";
    let btnId = "toggle_"+ param;
    let button: HTMLButtonElement = document.getElementById(btnId) as HTMLButtonElement;

    let classes = param.split('_');
    let items = document.querySelectorAll("ul.group-"+ classes[0] +" > li.item-"+ classes[1]+ " > ul > li");

    if (button != null) {
        items.forEach(function(item, index) {
            /* If list contains .show class, remove it, else do the inverse */
            if (item.classList.contains(className)) {
                item.classList.remove(className);
                /* Add this class to add opacity on the last visible element */
                if (index === 5) {
                    item.classList.add('read-more-list');
                }
                newText = "Voir plus";
            } else {
                item.classList.add(className);
                /* Remove opacity on limiter element */
                if (item.classList.contains('read-more-list')) {
                    item.classList.remove('read-more-list');
                }
                newText = "Voir moins";
            }
        });
        button.innerHTML = newText;
        const buttonParentNode: HTMLElement = button.parentNode as HTMLElement;
        buttonParentNode.scrollIntoView();
    }
}
