import React from 'react';
import { useForm } from "react-hook-form";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faInstagram, faVimeoV, faYoutube, faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons';
import * as site from '../../core';
import './Footer.scss'
import {Link} from 'react-router-dom';
const axios = require('axios').default;

export default function Footer() {
    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {

        axios.post(site.api_url + 'subscription', data)
          .then((response) => {
            let msg = document.getElementById('newsletter').querySelector('.modal-body .msg');
            msg.innerHTML = '<div class="alert text-center no-radius alert-primary alert-dismissible fade show" role="alert"><b>Féliciations !</b><br/><b>'+data.firstname +'</b> est désormais inscrit(e) à notre lettre d\'informations.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>'
        ;
        }, (error) => {
            console.log(error);
        });
    };

    return (
        <footer>
            {/* Brand */}
            <div className='b-top'>
                <div className='b-logo'>
                    <a href='/' title="Retourner à l'accueil">
                        <img src='/assets/files/logos/cdlp_white.png' alt='CDLP Logo' />
                    </a>
                </div>
                <div className='b-title'>
                    <span className={'h1'}>{site.name}</span>
                    <div className='slogan'>{site.slogan}</div>
                </div>
            </div>
            {/* Details */}
            <div className='address'>
                <a className='d-inline-block text-link' title='Nous rencontrer' href={site.address} target="_blank">
                    10 rue de Paris 78100 Saint-Germain-en-Laye
                </a>
            </div>
            {/* Socials networks */}
            <div className='socials'>
                <a href='https://www.facebook.com/CDLPofficiel/' target='_blank' rel={'noopener noreferrer'} title='Rejoignez-nous sur Facebook'>
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href='https://www.instagram.com/cdlpofficiel/' target='_blank' rel={'noopener noreferrer'} title='Retrouvez-nous sur Instagram'>
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href='https://twitter.com/le_cdlp/' target='_blank' rel={'noopener noreferrer'} title='Suivez-nous sur Twitter'>
                    <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href='https://vimeo.com/collectifdanslapeau/' target='_blank' rel={'noopener noreferrer'} title='Nos vidéos sur Vimeo'>
                    <FontAwesomeIcon icon={faVimeoV} />
                </a>
                <a href='https://www.youtube.com/channel/UCJjB6KPOBNY9wJwklyo7Ctg/' target='_blank' rel={'noopener noreferrer'} title='Toutes nos vidéos sur YouTube'>
                    <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a href='https://www.linkedin.com/company/collectif-dans-la-peau/' target='_blank' rel={'noopener noreferrer'} title='Rejoignez nous sur LinkedIn'>
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
            </div>
            {/* Menu links */}
            <div className='links'>
                <Link to='/plan-du-site'>Plan du site</Link>
                <Link to='/mentions-legales'>Mentions Légales</Link>
                <button type="button" className={"btn-none"} data-toggle="modal" data-target="#formModal">
                    Newsletter
                </button>
            </div>
            {/* Modal newsletter form */}
            <div className={"modal fade"} id="formModal" tabIndex="-1" role="dialog" aria-labelledby="formModalLabel" aria-hidden="true">
                <div className={"modal-dialog"} role="document">
                    <div className={"modal-content no-radius"}>
                        <form onSubmit={handleSubmit(onSubmit)} id={"newsletter"} autoComplete="off">
                            <div className={"modal-header no-radius"}>
                                <h5 className={"modal-title text-primary text-center text-bold"} id="formModalLabel">Inscription à la newsletter</h5>
                                <button type="button" className={"close"} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className={"modal-body"}>
                                <div className={"text-primary text-center mb-3"}>Entrez vos informations pour recevoir la newsletter</div>
                                {/* Firstname */}
                                <div className={"form-group"}>
                                    <input 
                                        className={"form-control no-radius"} 
                                        type="text" 
                                        name="firstname" 
                                        autoComplete="false"
                                        placeholder="Votre prénom *" 
                                        {...register('firstname', {
                                            required: true, 
                                            pattern: {
                                                value: /^[a-zA-Z]{2,150}$/,
                                                message: 'Ce prénom n\'est pas valide.'
                                            }
                                        })} 
                                    />
                                </div>
                                {/* Name */}
                                <div className={"form-group"}>
                                    <input 
                                        className={"form-control no-radius"} 
                                        type="text" 
                                        name="name" 
                                        placeholder="Votre nom *" 
                                        {...register('name', {
                                            required: true, 
                                            pattern: {
                                                value: /^[a-zA-Z]{2,150}$/,
                                                message: 'Ce nom n\'est pas valide.'
                                            }
                                        })} 
                                    />
                                </div>
                                {/* Email */}
                                <div className={"form-group"}>
                                    <input 
                                        className={"form-control no-radius"} 
                                        type="text" 
                                        name="email" 
                                        placeholder="Votre email *" 
                                        {...register('email', {
                                            required: true, 
                                            pattern: {
                                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                                message: 'Cet email n\'est pas valide.'
                                            }
                                        })} 
                                    />
                                </div>
                                <div className={"msg"}></div>
                            </div>
                            <div className={"modal-footer no-radius"}>
                                <input type="submit" className={"btn btn-primary no-radius"} value="Valider" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
    );
}
